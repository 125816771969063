import { MouseEvent } from 'react';
import styles from './GroupConsentFormMenuList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConsentFormResponseList, GroupDetailsTab } from 'pages/Groups/Interfaces/Groups';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { faCircle as RegularCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle as SolidCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

interface GroupConsentFormMenuListProps {
  isLoading: boolean;
  groupId: string;
  activeId: string;
  consentFormMenuList: ConsentFormResponseList[];
}

const GroupConsentFormMenuList = ({
  isLoading,
  groupId,
  activeId,
  consentFormMenuList
}: GroupConsentFormMenuListProps) => {
  const navigate = useNavigate();
  const { GROUPS } = useRoutesGenerator();

  const handleClickCosentForm = (id: string) => (e: MouseEvent) => {
    e.stopPropagation();
    navigate(`${GROUPS.BASE}/${groupId}/${GroupDetailsTab.Consent}/${id}`);
  };

  return (
    <>
      {isLoading ? (
        <LoadingCircle />
      ) : (
        <>
          {consentFormMenuList && consentFormMenuList?.length > 0 ? (
            <div className={styles.container}>
              {consentFormMenuList.map(({ _id, name, createdAt }) => (
                <div id={_id} className={styles.link} key={_id} onClick={handleClickCosentForm(_id)}>
                  <FontAwesomeIcon className={styles.icon} icon={activeId === _id ? SolidCircle : RegularCircle} />
                  <div className={styles.label}>
                    <span className={styles.name}>{name}</span>
                    <span>{moment(createdAt).format('DD/MM/YY')}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>No Consent Forms</div>
          )}
        </>
      )}
    </>
  );
};

export default GroupConsentFormMenuList;
